<template>
  <tr
    class="row"
    :class="{ inactive: objUser.active != true }"
    v-if="userPage"
  >
    <td class="td-photo">
      <div class="photo-user greyRow">
        <img
          :src="objUser.src"
          v-if="objUser.src != false && objUser.src != null && objUser.error != true"
          @error="Object.assign(objUser, { error: true })"
          class="cover"
        />
        <i
          class="fas fa-user photo-icon"
          v-else
        ></i>
      </div>
    </td>
    <td class="td-text">
      <div class="internal" :ref="objUser.refInternal">
        {{ objUser.user_internal }}
      </div>
    </td>
    <td class="td-text">
      <div @click="$emit('detail', objUser)" class="name-detail line-clamp text-truncate" :ref="'userName' + objUser.user_id">
        {{ objUser.professional_name }}
      </div>
    </td>
    <td class="td-text line-clamp" @mouseenter="Gems.TooltipIfNeeded($event.target)">
      <div>{{ objUser.user_job ?? '[Sem função]' }}</div>
    </td>
    <td class="btn">
      <button
        :class="(objUser.has_matrix == 0 || objUser.show_summary == false) && 'disabled'"
        @click="$router.push({name: 'summary', state: { userId: objUser.user_id }})"
        :disabled="objUser.has_matrix == 0 || objUser.show_summary == false"
      >
        <i
          class="fas fa-chart-pie row-icons"
          :class="(objUser.has_matrix == 1 && objUser.show_summary) && 'ico-color'"
          aria-hidden="true"
          :ref="objUser.refSummary"
        ></i>
      </button>
      <button
        :class="objUser.has_matrix == 0 && 'disabled'"
        @click="$router.push({name: 'matrix', state: { userId: objUser.user_id }})"
        :disabled="objUser.has_matrix == 0"
      >
        <i
          class="fas fa-tachometer-alt row-icons"
          :class="objUser.has_matrix == 1 && 'ico-color'"
          aria-hidden="true"
          :ref="objUser.refMatrix"
        ></i>
      </button>
      <button
        :class="objUser.has_team == 0 && 'disabled'"
        @click="$router.push({name: 'user-teams', state: { userId: objUser.user_id }})"
        :disabled="objUser.has_team == 0"
      >
        <i
          class="fas fa-users row-icons"
          :class="objUser.has_team > 0 && 'ico-color'"
          aria-hidden="true"
          :ref="objUser.refTeams"
        ></i>
      </button>
      <button
        :class="((showInactives && objUser.has_subordinates == 0) || (!showInactives && objUser.has_subordinates_active == 0))
                  && 'disabled'"
        @click="$emit('subordinate', objUser)"
        :disabled="(showInactives && objUser.has_subordinates == 0) || (!showInactives && objUser.has_subordinates_active == 0)"
      >
        <i
          class="fas fa-sitemap row-icons"
          :class="((showInactives && objUser.has_subordinates > 0) || (!showInactives && objUser.has_subordinates_active > 0))
                  && 'ico-color'"
          aria-hidden="true"
          :ref="objUser.refShow"
        ></i>
      </button>
    </td>
  </tr>

  <div v-if="cardUser" class="user inner-card">
    <div class="photo">
      <img
        :src="objUser.src"
        v-if="
          objUser.src !== false && objUser.src !== null && objUser.error != true
        "
        @error="Object.assign(objUser, { error: true })"
        class="cover"
      />
      <i
        class="fas fa-user icon-user fa-3x"
        aria-hidden="true"
        v-else
      ></i>
    </div>

    <div class="user-info">
      <p :ref="'userName' + objUser.user_id" style="font-size: 15px; margin-top: 0px">
        {{ objUser.professional_name }}
      </p>

      <p ref="userJob" @mouseenter="Gems.TooltipIfNeeded($refs.userJob)" class="line-clamp" >
        {{ objUser.user_job ?? '[sem função]'}}
      </p>
      
      <div v-if="typeof objUser.user_team === 'undefined'"></div>
      <div v-else-if="objUser.user_team.length !== 0">
        <p
          v-if="objUser.user_team.length === 1"
          style="margin-top: 0px; font-size: 12px"
        >
          {{ objUser.user_team[0] }}
        </p>
        <p
          style="margin-top: 0px; font-size: 12px"
          ref="teams"
          v-else-if="objUser.user_team.length > 1"
        >
          {{ objUser.user_team.length }} equipas
        </p>
      </div>
      <p
        v-else
        style="margin-top: 0px; font-size: 12px"
      >[sem equipa]</p>
    </div>
  </div>

  <div v-if="teamUser" class="person">
    <tr
      class="person-data"
      v-if="openTeam === false"
      :class="event == false ? '' : 'click'"
    >
      <td>
        <input
          class="chk-leader"
          ref="leader"
          type="checkbox"
          :checked="objTeamUser.leader"
          @click.stop="$emit('Leader-Checked', objTeamUser)"
          v-if="objTeamUser.was_on_team == 0"
        />
      </td>
      <td
        ref="userName"
        class="userName"
      >
        {{ objTeamUser.professional_name }}
      </td>
      <td
        v-if="objTeamUser.user_template"
        ref="template"
        @mouseenter="Gems.TooltipIfNeeded($refs.template)"
        class="userTemplate"
      >
        {{ objTeamUser.user_template }}
      </td>
      <td
        v-else
        class="userTemplate"
      >[sem modelo]</td>
      <td v-if="objTeamUser.user_template">
        <i
          class="fas fa-times delete-template"
          aria-hidden="true"
          ref="removeTemplate"
          @click.stop="$emit('exclude-template', objTeamUser)"
        ></i>
      </td>
      <td v-else></td>
      <td>
        <i
          class="fas fa-user-times icon-team"
          ref="delete"
          aria-hidden="true"
          @click.stop="$emit('exclude', objTeamUser)"
          v-if="objTeamUser.was_on_team == 0"
        ></i>
      </td>
    </tr>

    <tr class="person-data-user-team" :class="{ leader: objTeamUser.leader == 1 ? 'leader' : '' }" v-else>
      <span>
        <i ref="teamLeaderStar" class="fas fa-star" v-if="objTeamUser.leader == 1"></i>
      </span>

      <span ref="userName" class="userNameUserTeams">{{objTeamUser.professional_name}}</span>

      <span ref="function" @mouseenter="Gems.TooltipIfNeeded($refs.function)">
        {{ objTeamUser.user_job ? objTeamUser.user_job : "[sem função]" }}
      </span>

      <a v-if="objTeamUser.has_matrix == 1">
        <i class="fas fa-tachometer-alt" aria-hidden="true"></i>
      </a>
      
      <span class="matrix-count" v-if="objTeamUser.matrix_count > 1 && objTeamUser.has_matrix == 1">{{objTeamUser.matrix_count}}</span>
    </tr>
  </div>
</template>

<script>
import { Gems } from "../gems"
import { mapActions } from "vuex"

export default {
  name: "UserBlock",
  props: {
    objUser: Object,
    cardUser: Boolean,
    userPage: Boolean,
    teamUser: Boolean,
    objTeamUser: Object,
    index: Number,
    openTeam: Boolean,
    row_count: Number,
    event: Boolean,
    loggedUserIsLeader: Boolean,
    canOpen: Boolean,
    showInactives: Boolean,
  },
  emits: [
    "exclude",
    "LeaderChecked",
    "detail",
    "subordinate",
    "exclude-template",
  ],
  data() {
    return {
      isDisabled: Boolean,
      error: false,
      Gems,
    };
  },
  methods: {
    ...mapActions(["ResetMatrix"]),

    UserPageTooltips() {
      let refSummary = this.objUser.refSummary
      let refMatrix = this.objUser.refMatrix
      let refShow = this.objUser.refShow
      let refTeams = this.objUser.refTeams
      let refInternal = this.objUser.refInternal

      if (this.$refs[refSummary]) 
        Gems.Tooltip(this.$refs[refSummary], "Ver síntese", "tooltip")

      if (this.$refs[refMatrix]) 
        Gems.Tooltip(this.$refs[refMatrix], "Matriz(es)", "tooltip")

      if (this.$refs[refShow]) 
        Gems.Tooltip(this.$refs[refShow], "Ver subordinados", "tooltip")

      if (this.$refs[refTeams]) 
        Gems.Tooltip(this.$refs[refTeams], "Ver equipas", "tooltip")

      if (this.$refs[refInternal]) 
        Gems.Tooltip(this.$refs[refInternal], "N° interno", "tooltip")

      if (this.$refs["userName" + this.objUser.user_id]) 
        Gems.Tooltip(this.$refs["userName" + this.objUser.user_id], this.objUser["complete_name"], "tooltip");
    },

    CardUserTooltips() {
      if (this.$refs.teams) {
        let teams = this.objUser.user_team.slice().toString()
        Gems.Tooltip(this.$refs.teams, `${teams.replaceAll(",", "\n")}`, "tooltip")
      }

      if (this.$refs["userName" + this.objUser.user_id]) 
        Gems.Tooltip(this.$refs["userName" + this.objUser.user_id], this.objUser.complete_name, "tooltip")
    },

    TeamUserTooltips() {
      if (this.objTeamUser.was_on_team == 0) {
        Gems.Tooltip(
          this.$refs.leader,
          "Marque esta opção para definir esta pessoa como líder da equipa, como líder a pessoa poderá ver a matriz de todos, independente da hierarquia",
          "tooltip"
        )
        
        if (this.$refs.delete)
          Gems.Tooltip(this.$refs.delete, `Remover ${this.objTeamUser.professional_name} da equipa`, "tooltip")
      }

      if (this.$refs.userName) 
        Gems.Tooltip(this.$refs.userName, this.objTeamUser.complete_name, "tooltip")

      if (this.$refs.removeTemplate) 
        Gems.Tooltip(this.$refs.removeTemplate, "Remover modelo/matriz do utilizador(a)", "tooltip")
    }
  },
  // computed: {
  //   ...mapGetters(["Matrix", "Show"]),
  // },
  mounted() {
    if (this.$refs.teamLeaderStar) {
      Gems.Tooltip(this.$refs.teamLeaderStar, "Team Leader", "tooltip");
    }

    if (this.teamUser && this.openTeam === false)
      this.TeamUserTooltips()

    if (this.openTeam) {
      if (this.$refs.userName) {
        Gems.Tooltip(
          this.$refs.userName,
          this.objTeamUser.complete_name,
          "tooltip"
        );
      }
    }

    if (this.cardUser) {
      this.CardUserTooltips()
    }

    if (this.userPage) {
        this.UserPageTooltips()
    }
  },
  updated() {
    if (this.userPage) 
      this.UserPageTooltips()

    if (this.cardUser) 
      this.CardUserTooltips()

    if (this.teamUser && this.openTeam === false) 
      this.TeamUserTooltips()
  },
  beforeRouteLeave() {
    this.ResetMatrix();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1400px) {
  .row {
    grid-template-columns: 7% 7% 33% 38% 14% !important;
  }
}
.row:nth-child(odd) {
  background-color: var(--special-app-color);
}

.row:nth-child(even) {
  background-color: var(--light-gray-color);
}

.row {
  display: grid;
  grid-template-columns: 7% 7% 33% 40% 13%;
  align-items: center;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  grid-auto-rows: 45px;
  margin-top: 3px;
}

.inactive {
  opacity: 0.4;
}

.td-text {
  word-wrap: break-word;
}

.photo-user {
  pointer-events: none;
  border: 2px solid white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: 0px;
  box-shadow: 0 0 10px 1px rgb(0, 0, 0, 0.1);
  text-align: center;
  outline: solid 1px rgb(245, 245, 245);
}

.photo-user img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.photo-icon {
  width: 25px;
  height: 36px;
  font-size: 21pt;
  padding-top: 4px;
}

.row-icons {
  font-size: 1.3em !important;
  padding-top: 12px;
  padding-bottom: 10px;
}

.ico-color {
  color: rgb(50, 50, 60);
}

.internal {
  font-weight: 500;
  cursor: default;
}

.name-detail {
  cursor: pointer !important;
  font-weight: 600;
  padding: 10px 5px 10px;
}

.name-detail:hover {
  color: blueviolet;
  font-weight: 600;
}

.btn button {
  width: 70px;
  height: 40px;
  background-color: transparent;
  border: transparent;
}

.disabled {
  color: #c6c6c6 !important;
  opacity: 0.8;
  cursor: default;
}

/* CSS cardUser */

.user {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info {
  margin-top: 5px;
}

.user-info p {
  margin: 5px;
  padding: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.user-info p:nth-child(2) {
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-top: 0px;
  word-break: break-word;
}

.inner-card:hover p:nth-child(2) {
  color: var(--default-app-color);
}

.photo {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 5px solid white;
  margin-top: -20px;
  position: relative;
  background-color: var(--default-app-color);
  color: white;
}

.photo img {
  width: 100%;
  height: 100%;
  /* margin: 8px; */
  border-radius: 50%;
}

.photo .icon-user {
  width: 75%;
  height: 75%;
  margin: 8px auto auto auto;
}

.btn {
  display: flex;
  text-align: right;
}

/* CSS teamUser */

.person-data-user-team {
  display: grid;
  grid-template-columns: 0.08fr repeat(2, 1fr) 0.1fr 0.1fr;
  height: 30px;
  align-items: center;
  margin-left: 10px;
  border-radius: 0px;
  vertical-align: middle;
}

.person .person-data td,
.person-data-user-team span {
  margin: 0px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.userNameUserTeams {
  text-align: start !important;
  margin-left: 5px !important;
}

.person-data {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.2fr 0.2fr;
  grid-gap: 0px;
  align-items: center;
  grid-auto-rows: 25px;
}

.icon-team {
  cursor: pointer !important;
}

.icon-team:hover {
  transform: scale(1.2);
  position: relative;
  z-index: 1;
}

.cover {
  object-fit: cover;
  width: 150px;
  height: 100px;
}

.leader {
  font-weight: 600;
}

.chk-leader {
  cursor: pointer !important;
}

.userName {
  text-align: start !important;
  cursor: default;
}

.userTemplate {
  cursor: default;
}

.delete-template {
  cursor: pointer !important;
}

.delete-template:hover {
  transform: scale(1.2);
  position: relative;
  z-index: 1;
}

.matrix-count {
  font-weight: bold;
  font-size: 1.2em;
  position: relative;
  left: -5px;
}

</style>
