<template>
  <div class="cycle-container">
    <div class="insert-cycle">
      <form>
        <input type="text" v-model="txtDesig" placeholder="Designação do ciclo" class="cycle-name" required />
        <input type="date" v-model="dateBegin" ref="beginDate" class="begin-date" required />
        <input type="date" v-model="dateEnd" ref="endDate" class="end-date" required />
      </form>
      <button class="btn-action" @click="ConfirmCreateCycle">CRIAR NOVO CICLO</button>
      <button @click="CloneCycle">CLONAR CICLO ATUAL</button>
    </div>

    <table class="table">
      <thead class="default-app-bg">
        <tr>
          <th>Designação do ciclo</th>
          <th class="date">Data Início</th>
          <th class="date">Data Fim</th>
          <th colspan="3">
            <table class="inner-table">
              <thead>
                <tr>
                  <th colspan="3">Opção de apuramento</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Integrado</td>
                  <td>Específico</td>
                  <td>Ponderado</td>
                </tr>
              </tbody>
            </table>
          </th>
          <th class="summary">Síntese (Própria)</th>
          <th class="summary">Síntese (Subordinado)</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr v-for="(cycle, i) in cycles" :key="cycle.cycle_id">
          <td class="td-name">
            <div
              class="icon-div cursor-pointer"
              @click.stop="ConfirmDeleteCycle(cycle.cycle_id)"
            >
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </div>
            <div class="icon-div cursor-pointer" @click="OpenDetail(cycle)">
              <i class="fas fa-plus-circle" aria-hidden="true"></i>
            </div>
            <input
              :ref="`txtDesig${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              class="cycle-title line-clamp"
              type="text"
              :placeholder="cycle.cycle_desig"
              :value="cycle.cycle_desig"
            />
          </td>
          <td class="date">
            <input
              :ref="`txtBegin${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              type="date"
              :value="cycle.cycle_begin"
              :id="cycle['cycle_id'] + ' begin'"
            />
          </td>
          <td class="date">
            <input
              :ref="`txtEnd${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              type="date"
              :value="cycle.cycle_end"
              :id="cycle['cycle_id'] + ' end'"
            />
          </td>
          <td>
            <input
              :ref="`txtIntegrated${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              class="checkbox"
              type="checkbox"
              v-model="cycle['integrated']"
            />
          </td>
          <td>
            <input
              :ref="`txtSpec${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              class="checkbox"
              type="checkbox"
              v-model="cycle['spec']"
            />
          </td>
          <td>
            <input
              :ref="`txtWeighted${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              class="checkbox"
              type="checkbox"
              v-model="cycle['weighted']"
            />
          </td>

          <td>
            <input
              :ref="`txtSummarySelf${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              class="checkbox"
              type="checkbox"
              v-model="cycle['summary_self']"
            />
          </td>
          <td>
            <input
              :ref="`txtSummary${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              class="checkbox"
              type="checkbox"
              v-model="cycle['summary_access']"
            />
          </td>
          <td>
            <select
              :ref="`txtActive${cycle['cycle_id']}`"
              @change="Update(cycle.cycle_id, i)"
              v-model="cycle['cycle_active']"
              class="state"
              :id="cycle['cycle_id']"
            >
              <option
                value="false"
                :selected="
                  cycle.cycle_active === 0 ||
                  cycle.cycle_active == false
                "
              >
                Inativo
              </option>
              <option
                value="true"
                :selected="
                  cycle.cycle_active === 1 || cycle.cycle_active == true
                "
              >
                Ativo
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn-action btn-save" @click="UpdateCycle">GRAVAR ALTERAÇÕES</button>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";
import { mapActions } from "vuex";

export default {
  name: "Cycle",
  data() {
    return {
      cycles: Array,
      dbCycles: Array,
      txtDesig: "",
      dateEnd: "",
      dateBegin: "",
      cycle_finished: String,
      changedCycles: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions(["ReloadMenu"]),

    Update(cycleId, index) {
      if (this.cycles[index]["cycle_id"] != cycleId)
        return

      Object.assign(this.cycles[index], { 
        changed: true,
        cycle_desig: this.$refs[`txtDesig${cycleId}`][0].value,
        cycle_begin: this.$refs[`txtBegin${cycleId}`][0].value,
        cycle_end: this.$refs[`txtEnd${cycleId}`][0].value,
        cycle_active: this.$refs[`txtActive${cycleId}`][0].value === 'true' ? true : false,
        integrated: this.$refs[`txtIntegrated${cycleId}`][0].checked,
        weighted: this.$refs[`txtWeighted${cycleId}`][0].checked,
        spec: this.$refs[`txtSpec${cycleId}`][0].checked,
        summary_access: this.$refs[`txtSummary${cycleId}`][0].checked,
        summary_self: this.$refs[`txtSummarySelf${cycleId}`][0].checked,
      })

      if (
        this.cycles[index]["cycle_desig"] == this.dbCycles[index]["cycle_desig"] &&
        this.cycles[index]["cycle_begin"] == this.dbCycles[index]["cycle_begin"] &&
        this.cycles[index]["cycle_end"] == this.dbCycles[index]["cycle_end"] &&
        this.cycles[index]["cycle_active"] == this.dbCycles[index]["cycle_active"] &&
        this.cycles[index]["integrated"] == this.dbCycles[index]["integrated"] &&
        this.cycles[index]["weighted"] == this.dbCycles[index]["weighted"] &&
        this.cycles[index]["spec"] == this.dbCycles[index]["spec"] &&
        this.cycles[index]["summary_access"] == this.dbCycles[index]["summary_access"] &&
        this.cycles[index]["summary_self"] == this.dbCycles[index]["summary_self"]
      ) {
        Object.assign(this.cycles[index], { changed: false })
      }
    },

    OpenDetail(cycle) {
      this.$router.push({
        name: "cycle-detail",
        state: {
          cycleId: cycle.cycle_id,
          cycleDesig: cycle.cycle_desig,
          cycleBegin: cycle.cycle_begin,
          cycleEnd: cycle.cycle_end_db,
          cycleActive: cycle.cycle_active,
        },
      });
    },

    ConfirmDeleteCycle(id) {
      Gems.Telegram(' Apagar o ciclo?<br/>(Ação irreversível)', "tele-info", "", "").then(
        () => {
          this.DeleteCycle(id);
        },
        () => {}
      );
    },

    async DeleteCycle(id) {
      if (id === localStorage.getItem("cycle")) {
        Gems.Telegram(
          "Não é possível apagar o ciclo em que se está inserido!",
          "tele-not-allowed"
        );
      } else {
        const response = await KPI.CallBackEnd("post", "delete-cycle", { id: id })
        
        if (response.status === 200) {
          Gems.Telegram("Ciclo apagado", "tele-success");
          this.GetCycles();
        } else {
          Gems.Telegram("Erro ao apagar!", "tele-not-allowed");
        }
      }
    },

    ValidateCycleChange(cycle) {
      if (cycle.txtDesig.replace(/\s/g, "") == "") {
        Gems.Telegram("Preencha o nome do ciclo", "tele-not-allowed");
        return false;
      }

      if (cycle.dateBegin.replace(/\s/g, "") == "") {
        Gems.Telegram("Preencha a data inicial", "tele-not-allowed");
        return false;
      }

      if (cycle.dateEnd.replace(/\s/g, "") == "") {
        Gems.Telegram("Preencha a data final", "tele-not-allowed");
        return false;
      }

      if(new Date(cycle.dateBegin).toISOString().slice(0, 10) > new Date(cycle.dateEnd).toISOString().slice(0, 10)){
        Gems.Telegram("A data final deve ser maior do que a data inicial", "tele-not-allowed");
        return false
      }

      for (let ind in this.cycles) {
        if (this.cycles[ind]["cycle_desig"] == cycle.txtDesig.toUpperCase()) {
          if(cycle.ind && cycle.ind != ind || !cycle.ind) {
            Gems.Telegram("Já existe um ciclo com este nome", "tele-not-allowed");
            return false;
          }
        }
      }
      return true;
    },

    ConfirmCreateCycle() {
      const cycle = { 
        txtDesig: this.txtDesig,
        dateBegin: this.dateBegin, 
        dateEnd: this.dateEnd,
        ind: false
      }

      if (!this.ValidateCycleChange(cycle)) 
        return

      Gems.Telegram(`Criar um novo ciclo chamado '${this.txtDesig.toUpperCase()}'`, "tele-info", "", "")
        .then(
          () => { this.CreateCycle() },
          () => {}
        );
    },

    async CreateCycle() {
      let cycleTemp = {
        designation: this.txtDesig.toUpperCase(),
        date_begin: this.dateBegin,
        date_end: this.dateEnd,
        active: 0,
        deleted: 0,
        spec: 1,
        weighted: 0,
        integrated: 0
      }

      const response = await KPI.CallBackEnd("post", "create-cycle", {
        cycle: cycleTemp,
        currentCycle: localStorage.getItem("cycle"),
      })

      if (response.status !== 200) {
        Gems.Telegram("Erro ao gravar!", "tele-not-allowed")
        return
      }

      Gems.Telegram("Operação realizada com sucesso!", "tele-success")
      this.txtDesig = ""
      this.dateBegin = ""
      this.dateEnd = ""
      this.GetCycles()
    },

    HasCycleChoosed() {
      if (localStorage.getItem("cycle") == "null" || localStorage.getItem("cycle") == null) 
        return false

      return true
    },

    async CloneCycle() {

      Gems.Telegram('Clonar o ciclo atual?', 'tele-info', '', '').then(async () => {
          if (!this.HasCycleChoosed()) {
            Gems.Telegram("É preciso fazer parte de um ciclo para clonar!", "tele-not-allowed")
            return
          }

          const cycle = { 
            txtDesig: this.txtDesig,
            dateBegin: this.dateBegin, 
            dateEnd: this.dateEnd,
            ind: null,
            id: localStorage.getItem("cycle")
          }

          if (!this.ValidateCycleChange(cycle)) 
            return

          const response = await KPI.CallBackEnd("post", "clone-cycle", {
            cycle
          })

          if (response.error) {
            Gems.Telegram("Erro ao clonar ciclo!", "tele-not-allowed")
            return
          } 

          this.GetCycles()
          Gems.Telegram("Ciclo clonado", "tele-success")
        }, () => {})
    },

    async UpdateCycle() {
      if (!(this.cycles.length > 0)) {
        Gems.Telegram("Não existe ciclo para ser atualizado!","tele-not-allowed")
        return
      }

      this.cycles = KPI.CovertNumToBool(this.cycles, "integrated");
      this.cycles = KPI.CovertNumToBool(this.cycles, "spec");
      this.cycles = KPI.CovertNumToBool(this.cycles, "weighted");

      for (let ind in this.cycles) {
        if (this.cycles[ind].changed) {
          this.cycles[ind].cycle_begin = document.getElementById(this.cycles[ind].cycle_id + " begin").value;
          this.cycles[ind].cycle_end = document.getElementById(this.cycles[ind].cycle_id + " end").value;

          const actualCycle = { txtDesig: this.cycles[ind].cycle_desig, dateBegin: this.cycles[ind].cycle_begin, dateEnd: this.cycles[ind].cycle_end, ind}
          if (!this.ValidateCycleChange(actualCycle)) 
            return

          if(!this.cycles[ind].integrated && !this.cycles[ind].spec && !this.cycles[ind].weighted) {
            Gems.Telegram(`Escolha ao menos um método avaliativo para o ciclo ${this.cycles[ind].cycle_desig}`, 'tele-not-allowed')
            this.changedCycles = []
            return
          }

          if (this.cycles[ind].cycle_end != this.cycles[ind].cycle_end_db && (this.cycles[ind].last_imported_file > this.cycles[ind].cycle_end)) {
            await Gems.Telegram(
              `Não é possível alterar a data fim do ciclo [${this.cycles[ind].cycle_desig}] para antes da última data de importação de resultados 
              ${this.cycles[ind].last_imported_file ? '[' + this.cycles[ind].last_imported_file + ']' : ''}`,
              "tele-info",
              ""
            )

            this.changedCycles = [];
            return;
          }

          this.changedCycles.push(this.cycles[ind]);
        }
      }

      if (this.changedCycles.length == 0) {
        Gems.Telegram("Nenhum ciclo foi alterado", "tele-not-allowed")
        return
      }

      const response = await KPI.CallBackEnd("put", "update-cycle", {
        cycles: this.changedCycles,
      })
      
      if (response.status === 200) {
        if (response.data.error == false) {
          this.ReloadMenu();
          Gems.Telegram("Gravado", "tele-success");
        } else {
          Gems.Telegram(response.data.message, "tele-not-allowed");
        }
        this.GetCycles();
      } else {
        Gems.Telegram("Erro ao gravar", "tele-not-allowed");
      }
    },

    async GetCycles() {
      const response = await KPI.CallBackEnd("get", "get-cycles")
          
      for (let elem of response.data[0]) {
        elem['integrated']     = elem['integrated']     == 0 ? false : true
        elem['spec']           = elem['spec']           == 0 ? false : true
        elem['weighted']       = elem['weighted']       == 0 ? false : true
        elem['summary_access'] = elem['summary_access'] == 0 ? false : true
        elem['summary_self']   = elem['summary_self']   == 0 ? false : true
      }

      this.cycles = response.data[0];
      this.dbCycles = JSON.parse(JSON.stringify(response.data[0]));
    },
  },
  mounted() {
    this.GetCycles();
    Gems.Tooltip(this.$refs.beginDate, "Data Início", "tooltip");
    Gems.Tooltip(this.$refs.endDate, "Data Fim", "tooltip");
  },
  updated() {
    for (let cycle of this.cycles) {
      if (this.$refs[`txtDesig${cycle.cycle_id}`])
        Gems.Tooltip(
          this.$refs[`txtDesig${cycle.cycle_id}`][0],
          cycle.cycle_desig,
          "tooltip"
        );
    }
    this.cycle_finished = localStorage.getItem("cycle_finished");
  },
};
</script>

<style scoped>
.cycle-container {
  margin: 20px;
}

.insert-cycle {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.insert-cycle form {
  flex: 4;
  display: flex;
  justify-content: space-between;
}

.insert-cycle input {
  height: 38px;
  padding: 0px 5px;
}

.insert-cycle button {
  flex: 1;
  margin-left: 5px;
}

.cycle-name {
  flex: 4;
}

.begin-date,
.end-date {
  flex: 1.1;
  margin-left: 5px;
}

.begin-date::-webkit-calendar-picker-indicator,
.end-date::-webkit-calendar-picker-indicator {
  position: relative;
  top: 2px;
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.inner-table {
  width: 100%;
  text-align: center;
  border: 0px solid;
}

.inner-table tbody td {
  font-size: 12px !important;
  color: #fff !important;
  height: 15px !important;
  text-align: center !important;
  margin-top: -5px;
}

.inner-table tbody tr {
  display: flex;
  justify-content: space-around;
}

.inner-table thead th {
  height: 10px !important;
}

.table td,
table.table th {
  border: 0px solid;
  height: 36px;
}

.table tbody td {
  font-size: 15px;
  color: #000000;
}

.table tbody td input {
  background: transparent;
  border: none;
  width: 100%;
  color: #000000;
  text-align: center;
}

.table tbody tr {
  border: 2px solid transparent;
}

.table .table-body tr:hover,
.table .table-body tr:hover select {
  background-color: var(--special-app-color);
}

.table tbody tr td:first-child {
  display: flex;
}

.table thead th {
  font-weight: 500;
  color: #fff;
  text-align: center;
  border-left: 1px solid #fff;
}

.table thead th:first-child {
  border-left: none;
}

.table thead th:last-child {
  width: 20px;
}

.td-name {
  align-items: center;
}

.cycle-title {
  text-align: left !important;
}

button {
  height: 40px;
  font-size: 1em !important;
}

button:hover {
  font-size: 1em !important;
}

.icon-div {
  width: 38px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.state {
  border: none;
  height: 100%;
}

.date input {
  text-align: initial !important;
}

.date,
.date input {
  width: 100px !important;
}

.date input::-webkit-calendar-picker-indicator {
  position: relative;
  top: 2px;
}

.summary {
  width: 7% !important;
}

.is-disabled {
  pointer-events: none;
}
.checkbox {
  width: 35% !important;
  transform: scale(1.5);
}

.icon-div i:hover {
  color: var(--default-app-color);
}

.btn-save {
  width: 17% !important;
  float: right;
  margin-top: 20px;
}

@media only screen and (max-width: 1400px) {
  .insert-cycle form {
    flex: 3;
  }

  .cycle-name {
    flex: 2;
  }

  .icon-div {
    margin-top: 5px;
    width: 33px;
    height: 25px;
  }
}
</style>
